import axios from '@utils/axios';
import _ from 'lodash';
const SUCESESS_CODE = [0, 200];
// const isDev = process.env.NODE_ENV === 'development';

// export * from './api2'

// 解析结果
export const resolve = fetchFn =>
  _.wrap(fetchFn, async (fetchFn, params) => {
    const res = await fetchFn(params);
    const { code, msg, data, message } = res || {};
    if (SUCESESS_CODE.includes(+code)) {
      return data;
    }
    throw { code, msg, data, message };
  });

/**
 * app接口更新
 */
// app名称 product
// 0=牧德控制器
// 1=

// 系统 type
// 0=安卓
// 1=iOS

/**
 * App更新
 */
export const app_update = resolve(params => {
  // const url = '/api/mode/app/update';
  const url = '/mode/app/update';
  return axios.get(url, { params });
});
/**
 * 产品说明书详情
 */
export const manual_find = resolve(params => {
  // const url = '/api/manual/find';
  const url = '/V1/manual/find';
  return axios.get(url, { params });
});
/**
 * 获取说明书列表
 */
export const selectManuals = resolve(params => {
  const url = '/V1/manual/selectManuals';
  return axios.get(url, { params });
});

/**
 *调查问卷接口
 */
export const survey_search = resolve(params => {
  // const url = '/api/survey/search';
  const url = '/V1/survey/search';
  return axios.get(url, { params });
});
export const survey_submit = resolve(params => {
  // const url = '/api/survey/submit';
  const url = '/V1/survey/submit';
  return axios.post(url, { params });
});
/**
 * 获取语言
 */
export const getLang = resolve(params => {
  const url = '/V1/common/getLanguage';
  return axios.get(url, { params });
});
/**
 * 获取菜单
 */
export const getMenu = resolve(params => {
  const url = '/V1/website/getMenu';
  return axios.get(url, { params });
});
/**
 * 获取Banner
 */
export const getBanner = resolve(params => {
  const url = '/V1/website/getBanner';
  return axios.get(url, { params });
});
/**
 * 获取行业应用
 */
export const getIndustryApplication = resolve(params => {
  const url = '/V1/website/getIndustryApplication';
  return axios.get(url, { params });
});
/**
 * 获取官网基本信息
 */
export const getInfo = resolve(params => {
  const url = '/V1/website/getInfo';
  return axios.get(url, { params });
});
/**
 * 获取热销产品
 */
export const getHotProduct = resolve(params => {
  const url = '/V1/website/getHotProduct';
  return axios.get(url, { params });
});
/**
 * 获取新闻动态列表
 */
export const selectNews = resolve(params => {
  const url = '/V1/media/selectNews';
  return axios.get(url, { params });
});
/**
 * 获取视频列表
 */
export const selectVideo = resolve(params => {
  const url = '/V1/media/selectVideo';
  return axios.get(url, { params });
});
/**
 * 获取案例列表
 */
export const selectCase = resolve(params => {
  const url = '/V1/media/selectCase';
  return axios.get(url, { params });
});
/**
 * 获取视频详情
 */
export const getVideo = resolve(params => {
  const url = '/V1/media/getVideo';
  return axios.get(url, { params });
});
/**
 * 获取新闻详情
 */
export const getNews = resolve(params => {
  const url = '/V1/media/getNews';
  return axios.get(url, { params });
});
/**
 * 获取案例详情
 */
export const getCase = resolve(params => {
  const url = '/V1/media/getCase';
  return axios.get(url, { params });
});
/**
 * 获取产品详情
 */
export const getProduct = resolve(params => {
  const url = '/V1/website/getProduct';
  return axios.get(url, { params });
});
/**
 * 获取公司资质
 */
export const getFirmAptitude = resolve(params => {
  const url = '/V1/website/getFirmAptitude';
  return axios.get(url, { params });
});
/**
 * 获取公司基本信息
 */
export const getFirmInfo = resolve(params => {
  const url = '/V1/website/getFirmInfo';
  return axios.get(url, { params });
});
/**
 * 获取学院列表需要认证
 */
export const selectCollege = resolve(params => {
  const url = '/V2/service/selectCollege';
  return axios.get(url, { params });
});
/**
 * 获取学院详情需要认证
 */
export const getCollege = resolve(params => {
  const url = '/V2/service/getCollege';
  return axios.get(url, { params });
});
/**
 * 获取产品列表需认证
 */
export const selectProduct = resolve(params => {
  const url = '/V2/service/selectProduct';
  return axios.get(url, { params });
});
/**
 * 提交服务咨询需认证
 */
export const submitServiceConsultation = resolve(data => {
  const url = '/V2/service/submitServiceConsultation';
  return axios.post(url, data);
});
/**
 * 获取服务咨询需认证
 */
export const selectServiceConsultation = resolve(data => {
  const url = '/V2/service/selectServiceConsultation';
  return axios.get(url, data);
});



//用户数据
/**
 * 登陆
 */
export const login = resolve(data => {
  const url = '/V1/person/login';
  return axios.post(url, data);
});

/**
 * 退出登陆
 */
export const logout = resolve(data => {
  const url = '/api/user/logout';
  return axios.post(url, data);
});
/**
 * 注册
 */
export const register = resolve(data => {
  const url = '/V1/person/register';
  return axios.post(url, data);
});
/**
 * 忘记密码/修改密码
 */
export const forgetPwd = resolve(data => {
  const url = '/V1/person/forgetPwd';
  return axios.post(url, data);
});
/**
 * 获取验证码 phonenumber ｜ email
 */
export const captcha = resolve(data => {
  const url = '/V1/person/captcha';
  return axios.post(url, data);
});
/**
 * 获取个人信息需认证
 */
export const selectPersonInfo = resolve(params => {
  const url = '/V2/person/selectPersonInfo';
  return axios.get(url, { params });
});

/**
 * 修改个人信息需认证
 */
export const updatePersonInfo = resolve(data => {
  const url = '/V2/person/updatePersonInfo';
  return axios.post(url, data);
});

/**
 * 查询国家信息列表
 */
export const getCountries = resolve(params => {
  const url = '/V1/common/countries';
  return axios.get(url, { params });
});

/**
 * 查询州省信息列表
 */
export const getStates = resolve(params => {
  const url = '/V1/common/states';
  return axios.get(url, { params });
});

/**
 * 查询城市信息列表
 */
export const getCities = resolve(params => {
  const url = '/V1/common/cities';
  return axios.get(url, { params });
});


