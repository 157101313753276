const KEY_accessToken = 'MODE_accessToken'

export const setAccessToken = (value) => {
    localStorage.setItem(KEY_accessToken, value)
}

export const getAccessToken = () => {
    return localStorage.getItem(KEY_accessToken)
}

// export const apiUrl = 'https://ai-test.modehoist.com/api/'//测试用
export const apiUrl = 'https://api.modehoist.com'//正式用