<template>
  <div>
    <div class="pc-header">
      <div class="brand-icon" @click="onBrandClick">
        <img :src="info.logoUrl" width="100%" />
      </div>
      <div class="nav">
        <div class="nav-left"></div>
        <div class="nav-right">
          <div
            :class="['nav-item', { active: item.id == clickId }]"
            v-for="(item, index) in menu"
            :key="index"
            @mouseover="showSubMenu(index)"
            @mouseleave="hideSubMenu"
            @click="gotoroute(item.path, item.id)"
          >
            <span class="titleone">{{ item.menuName }}</span>
            <div
              class="second-nav"
              v-if="index === activeNavItem && item.childrenStyle === '1'"
            >
              <div
                :class="['second-nav-item']"
                v-for="(product, index) in item.children"
                :key="index"
                @click="
                  gotoProductDetail(product.path, product.id, product.parentId)
                "
              >
                {{ product.menuName }}
              </div>
            </div>
            <div
              class="product"
              v-if="index === activeNavItem && item.childrenStyle === '0'"
            >
              <div
                :class="['product-item']"
                v-for="(product, index) in item.children"
                :key="index"
                @click="
                  gotoProductDetail(product.path, product.id, product.parentId)
                "
              >
                <img :src="product.coverUrl" alt="" />
                <p>{{ product.menuName }}</p>
              </div>
            </div>
          </div>
          <div class="nav-item">
            <el-select
              size="mini"
              :value="lang"
              @change="onChangeLang"
              style="width: 70px"
            >
              <el-option
                v-for="item in lang_options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                v-model="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <template v-if="false">
            <div
              v-if="ModeToken"
              :class="['user-item', 'nav-item', { active: curNav1 === '4' }]"
              @click="() => onNavClick(4)"
            >
              <i class="el-icon-user"></i>
            </div>
            <div v-else class="nav-item" @click="onLogin">
              {{ $t("index.登录") }}
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { goto } from "@utils/goto";
import { getMenu, getInfo, selectPersonInfo } from "@/service/api";
import RouteMap, { activeRoute } from "./RouteMap";

export default {
  computed: {
    ...mapState(["lang", "profile"]),
    ...mapGetters(["isLogin"]),
    curNav1() {
      if (/^([^_]*)[_]/.test(this.curNav)) {
        return RegExp.$1;
      }
      return undefined;
    },
  },
  data() {
    return {
      clickId: "",
      info: {},
      menu: {},
      activeNavItem: null,
      show: false,
      curNav: activeRoute,
      ModeToken: localStorage.getItem("MODE_accessToken"),
      lang_options: [
        { value: "en-US", label: "US" },
        { value: "zh-CN", label: "CN" },
      ],
    };
  },
  mounted() {
    this.Info();
    this.fetchList();
    this.clickId = localStorage.getItem("clickMenuId");
    // console.log(this.clickId);
    if (localStorage.getItem("MODE_locallanguage")) {
      setTimeout(() => {
        this.setLang(localStorage.getItem("MODE_locallanguage"));
      }, 600);
    } else {
      if (navigator.language != "zh-CN") {
        localStorage.setItem("MODE_locallanguage", "en-US");
        window.location.reload();
      }
    }
  },
  methods: {
    ...mapActions(["setLang"]),
    onNavClick(navIdx, nav2Idx = 0) {
      this.curNav = `${navIdx}_${nav2Idx}`;
      const url = RouteMap[this.curNav];
      console.log(url);
      if (url) {
        goto(url);
      }
    },
    onChangeLang(lang) {
      // this.setLang(lang);
      localStorage.setItem("MODE_locallanguage", lang);
      window.location.reload();
    },
    onLogin() {
      goto("/login");
    },
    onBrandClick() {
      goto("/");
    },
    gotoProductDetail(path, id, prodectId) {
      if (path == "") {
        alert(this.$t("index.敬请期待"));
        localStorage.removeItem("clickMenuId");
      } else {
        goto(path + "&menuId=" + id);
        localStorage.setItem("clickMenuId", prodectId);
      }
    },
    gotoroute(path, prodectId) {
      localStorage.setItem("clickMenuId", prodectId);
      if (path) {
        goto(path);
      }
    },
    showPopup() {
      this.show = true;
      this.$refs.form && this.$refs.form.reset();
    },
    closePopUp() {
      this.show = false;
    },
    async Info() {
      this.menu = (await getMenu()) || {};
      this.info = (await getInfo()) || {};
      console.log(this.menu);
    },
    async fetchList() {
      try {
        await selectPersonInfo();
      } catch (res) {
        this.ModeToken = false;
      }
    },
    showSubMenu(navItemId) {
      this.activeNavItem = navItemId;
    },
    hideSubMenu() {
      this.activeNavItem = null;
    },
  },
};
</script>

<style lang='scss' scoped>
@import "~@assets/styles/vars.scss";
@import "~@assets/styles/mixin.scss";
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pc-header {
  // box-shadow: 0px 0px 12px 0px rgba(215, 215, 215, 0.5);
  position: fixed;
  top: 0;
  z-index: 20;
  @include flex-center("row");
  padding-left: 25px;
  padding-right: 25px;
  height: 70px;
  width: 100vw;
  // color: #ffffff;
  background-color: rgba(0, 0, 0, 0.7);
  .brand-icon {
    height: 100%;
    width: 120px;
    object-fit: contain;
    // margin-right: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: relative;
  }
  .nav {
    flex: 1;
    @extend .flex-between;
    flex-flow: nowrap;
    .nav-item {
      height: 70px;
      display: flex;
      align-items: center;
      line-height: 24px;
      font-size: 16px;
      position: relative;
      color: #ffffff;
      cursor: pointer;
      .second-nav {
        //  display: flex;
        // display: none;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 70px;
        position: absolute;
        z-index: 20;
        // height: 140px;
        color: #ffffff;
        background-color: rgba(0, 0, 0, 0.7);
        text-align: center;
        .second-nav-item {
          padding: 0 7px;
          line-height: 38px;
          font-size: 16px;
        }
        .second-nav-item:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
      .product {
        position: fixed;
        left: 0;
        top: 70px;
        background-color: rgba(0, 0, 0, 0.7);
        width: 100vw;
        display: flex;
        justify-content: center;
        .product-item {
          width: 150px;
          height: 150px;
          text-align: center;
          padding: 15px 10px;
          margin: 0 25px;
          color: #fff;
          font-size: 16px;
          img {
            width: 76px;
            height: 72px;
            object-fit: cover;
          }
        }
        .product-item:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
    .user-item {
      i {
        font-size: 20px;
      }
    }
    .nav-item:hover {
      color: red;
    }
    .nav-right {
      @extend .flex-end;
      flex-basis: 280px;
      white-space: nowrap;
      .nav-item {
        padding: 0 15px;
      }
      .active {
        color: red;
      }
    }
  }
}
</style>
